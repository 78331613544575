import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import './styles.css'


const Bio = () => {
    const data = useStaticQuery(graphql`
        query {
          site {
            siteMetadata {
                social {
                    email,
                    github,
                    linkedin
                }
            }
          }
        }
      `)

    const social = data.site.siteMetadata.social
    const TopIcons = styled.div`
      font-size: 3vw;
     
      @media screen and (max-width : 768px) {
        font-size: 7vw;
      }

      @media screen and (max-width : 400px) {
        font-size: 6vw;
        display: flex;
      }
    `

    const IconLink = styled.a`
      :not(:first-child){
        padding-left: 25px;
      }
    `

    return (
        <TopIcons>
            <IconLink href={`mailto:${social.email}`}>
                <FontAwesomeIcon css={{
                    color: "black"
                }} icon={faEnvelopeOpen}/>
            </IconLink>
            <IconLink href={`https://github.com/${social.github}`}>
                <FontAwesomeIcon css={{
                    color: "#6e5494"
                }} icon={faGithub}/>
            </IconLink>
            <IconLink href={`https://linkedin.com/in/${social.linkedin}`}>
                <FontAwesomeIcon css={{
                    color: "#0072b1"
                }} icon={faLinkedin}/>
            </IconLink>
        </TopIcons>
    )
}
export default Bio

