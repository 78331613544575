import * as React from 'react'
import { Link } from 'gatsby'
import './styles.css'
import Bio from './bio'
import Footer from './footer'
import {NavLinks, PageTitle, NavLinkItem} from './style'
import styled from 'styled-components'


const StyledLink = styled(props => <Link {...props} />)`
  color: dimgrey;
`;

const StyledPageTitle = styled(props => <PageTitle {...props} />)`
  @media screen and (max-width : 400px) {
    font-size: 5vw;  }
`;

const Layout = ({ pageTitle, children }) => {
return (
    <div>
        <Bio></Bio>
            <NavLinks>
                <NavLinkItem>
                    <StyledLink to="/">/home</StyledLink>
                </NavLinkItem>
                <NavLinkItem>
                    <StyledLink to="/blog">/blog</StyledLink>
                </NavLinkItem>
            </NavLinks>

        <StyledPageTitle>{pageTitle}</StyledPageTitle>
        <title>superdevx</title>
            {children}
        <Footer>
        </Footer>
    </div>
    )
}

export default Layout

