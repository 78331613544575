import styled from 'styled-components'


export const PageStyle = styled.div`
    color: #232129;
    padding: 96px;
    font-family: -apple-system, Roboto, sans-serif, serif;
    @media screen and (max-width : 400px) {
        margin-bottom: 50px;
    }   
`

export const NavLinks = styled.ul`
    display: flex;
    list-style: none;
    padding-left: 0;
    font-family: 'Amiga4Ever';

    @media screen and (max-width : 400px) {
      display: inline-table;
    }
`

export const NavLinkItem = styled.li`
    padding-right: 20px;
    padding-bottom: 10px;
`

export const PageTitle = styled.h1`
    color: #1099A8;
    font-family: 'Amiga4Ever';
`

export const ListItemStyles = {
    fontWeight: 300,
    fontSize: 24,
    maxWidth: 560,
    marginBottom: 30,
}

export const DescriptionStyle = {
    color: "#232129",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 0,
    lineHeight: 1.25,
}

export const BadgeStyle = {
    fontFamily: "amiga4ever",
    color: "#fff",
    backgroundColor: "#088413",
    border: "1px solid #088413",
    fontSize: 11,
    fontWeight: "bold",
    letterSpacing: 1,
    borderRadius: 4,
    padding: "4px 6px",
    display: "inline-block",
    position: "relative",
    top: -2,
    marginLeft: 10,
    lineHeight: 1,
}

export const PageContent = styled.p`
`